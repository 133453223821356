import React, { useState } from 'react';
import { ChevronDown, Check } from 'lucide-react';

const sortOptions = [
  { value: 'popular', label: 'Most Popular' },
  { value: 'rating', label: 'Best Rating' },
  { value: 'newest', label: 'Newest' },
];

const filterCategories = [
  {
    name: 'Category',
    options: ['Makeup Artists', 'Hair Stylists', 'Dress Designers', 'Coaches'],
  },
  {
    name: 'Experience',
    options: ['Beginner-friendly', 'Experienced', 'Celebrity'],
  },
  {
    name: 'Price Range',
    options: ['$', '$$', '$$$', '$$$$'],
  },
  {
    name: 'Specialization',
    options: ['Teen Pageants', 'Miss USA', 'International Pageants'],
  },
];

const PageantVendorFilters = () => {
  const [selectedSort, setSelectedSort] = useState('popular');
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});

  const handleSortChange = (value) => {
    setSelectedSort(value);
    setOpenDropdown(null);
  };

  const toggleDropdown = (name) => {
    setOpenDropdown(openDropdown === name ? null : name);
  };

  const toggleFilter = (category, option) => {
    setSelectedFilters(prev => {
      const categoryFilters = prev[category] || [];
      const updatedFilters = categoryFilters.includes(option)
        ? categoryFilters.filter(item => item !== option)
        : [...categoryFilters, option];
      
      return {
        ...prev,
        [category]: updatedFilters
      };
    });
  };

  const removeFilter = (category, option) => {
    setSelectedFilters(prev => ({
      ...prev,
      [category]: prev[category].filter(item => item !== option)
    }));
  };

  const allSelectedFilters = Object.entries(selectedFilters).flatMap(
    ([category, options]) => options.map(option => ({ category, option }))
  );

  const getSelectedSortLabel = () => {
    return sortOptions.find(option => option.value === selectedSort)?.label || 'Sort';
  };

  return (
    <div className="bg-white border-b border-gray-200">
      <div className=" mx-auto">
        <div className="flex items-center justify-between py-4 px-4 sm:px-6 lg:px-8">
          <div className="relative">
            <button
              onClick={() => toggleDropdown('sort')}
              className="bg-white border border-gray-300 rounded-md px-3 py-2 text-sm leading-5 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {getSelectedSortLabel()}
              <ChevronDown className="ml-2 inline-block" size={16} />
            </button>
            {openDropdown === 'sort' && (
              <div className="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {sortOptions.map(option => (
                    <button
                      key={option.value}
                      onClick={() => handleSortChange(option.value)}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      {option.label}
                      {selectedSort === option.value && (
                        <Check className="ml-2 inline-block text-indigo-600" size={16} />
                      )}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="flex items-center space-x-4">
            {filterCategories.map(category => (
              <div key={category.name} className="relative">
                <button
                  onClick={() => toggleDropdown(category.name)}
                  className="bg-white border border-gray-300 rounded-md px-3 py-2 text-sm leading-5 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {category.name}
                  <ChevronDown className="ml-2 inline-block" size={16} />
                </button>
                {openDropdown === category.name && (
                  <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {category.options.map(option => (
                        <label key={option} className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                          <input
                            type="checkbox"
                            checked={selectedFilters[category.name]?.includes(option) || false}
                            onChange={() => toggleFilter(category.name, option)}
                            className="mr-2 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {allSelectedFilters.length > 0 && (
          <div className="bg-gray-100 py-3 px-4 sm:px-6 lg:px-8">
            <div className="flex items-center">
              <span className="text-sm text-gray-500 mr-4">Filters</span>
              <div className="flex flex-wrap gap-2">
                {allSelectedFilters.map(({ category, option }) => (
                  <span key={`${category}-${option}`} className="bg-purple-100 text-purple-800 text-xs font-medium px-2.5 py-0.5 rounded-full flex items-center">
                    {option}
                    <button onClick={() => removeFilter(category, option)} className="ml-1 focus:outline-none">
                      <svg className="h-3 w-3 text-purple-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageantVendorFilters;