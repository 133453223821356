import React, { useState, useEffect } from 'react';
import { Search, ChevronRight } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// Simulated help articles data with categories
const helpArticles = [
  { id: 1, title: "How to create an account", content: "To create an account, click on the 'Sign Up' button...", category: "Account Management" },
  { id: 2, title: "Submitting your pageant application", content: "To submit your pageant application, ensure you have...", category: "Pageant Participation" },
  { id: 3, title: "Tips for a successful pageant interview", content: "Preparing for your pageant interview is crucial...", category: "Pageant Preparation" },
  { id: 4, title: "Updating your profile information", content: "To update your profile information, go to your account settings...", category: "Account Management" },
  { id: 5, title: "Choosing the right pageant category", content: "When selecting a pageant category, consider your strengths...", category: "Pageant Participation" },
  { id: 6, title: "Pageant makeup tips", content: "For your pageant makeup, focus on a natural and polished look...", category: "Pageant Preparation" },
  // Add more articles as needed
];

const categories = [...new Set(helpArticles.map(article => article.category))];

const HelpPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [autoCompleteResults, setAutoCompleteResults] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [aiResponse, setAiResponse] = useState('');

  useEffect(() => {
    if (searchQuery.length > 2) {
      const results = helpArticles.filter(article => 
        article.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setAutoCompleteResults(results.slice(0, 5)); // Limit to 5 suggestions
    } else {
      setAutoCompleteResults([]);
    }
  }, [searchQuery]);

  const handleSearch = (e) => {
    e.preventDefault();
    const results = helpArticles.filter(article => 
      article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      article.content.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(results);
    setSelectedCategory(null);

    if (results.length === 0) {
      // Simulated AI response - replace with actual API call
      setAiResponse(`I couldn't find any articles matching "${searchQuery}", but here's what I can tell you: [AI-generated response would go here]`);
    } else {
      setAiResponse('');
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSearchResults(helpArticles.filter(article => article.category === category));
    setSearchQuery('');
    setAiResponse('');
  };

  const highlightMatch = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) => 
      part.toLowerCase() === query.toLowerCase() ? 
        <span key={index} className="text-purple-600 font-bold">{part}</span> : part
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow bg-white py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto">
          <h1 className="text-4xl font-bold text-purple-800 text-center mb-8">Help Center</h1>
          
          {/* Search Section */}
          <section className="mb-12">
            <form onSubmit={handleSearch} className="mb-4 relative">
              <div className="flex">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search for help articles..."
                  className="flex-grow px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
                <button type="submit" className="bg-purple-600 text-white px-4 py-2 rounded-r-md hover:bg-purple-700 transition duration-300">
                  <Search size={20} />
                </button>
              </div>
              {autoCompleteResults.length > 0 && (
                <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 shadow-lg">
                  {autoCompleteResults.map(article => (
                    <li 
                      key={article.id} 
                      className="px-4 py-2 hover:bg-purple-50 cursor-pointer"
                      onClick={() => {
                        setSearchQuery(article.title);
                        setAutoCompleteResults([]);
                      }}
                    >
                      {highlightMatch(article.title, searchQuery)}
                    </li>
                  ))}
                </ul>
              )}
            </form>
          </section>
          
          {/* Categories Section */}
          <section className="mb-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {categories.map(category => (
              <button
                key={category}
                onClick={() => handleCategorySelect(category)}
                className="bg-purple-50 p-4 rounded-md text-left hover:bg-purple-100 transition duration-300"
              >
                <h3 className="text-lg font-semibold text-purple-700 flex items-center justify-between">
                  {category}
                  <ChevronRight size={20} className="text-purple-600" />
                </h3>
              </button>
            ))}
          </section>
          
       
          <section>
            {searchResults.length > 0 || selectedCategory ? (
              <div>
                <h2 className="text-2xl font-semibold text-purple-700 mb-4">
                  {selectedCategory ? `Articles in ${selectedCategory}` : 'Search Results'}
                </h2>
                <ul className="space-y-4">
                  {searchResults.map(article => (
                    <li key={article.id} className="bg-purple-50 p-4 rounded-md">
                      <h3 className="text-lg font-semibold text-purple-700">{article.title}</h3>
                      <p className="text-black mt-2">{article.content}</p>
                    </li>
                  ))}
                </ul>
              </div>
            ) : aiResponse ? (
              <div className="bg-purple-50 p-4 rounded-md">
                <h2 className="text-2xl font-semibold text-purple-700 mb-4">AI Assistant Response</h2>
                <p className="text-black">{aiResponse}</p>
              </div>
            ) : null}
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default HelpPage;