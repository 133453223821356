import React, { useState } from 'react';
import LPHero from '../components/LPHero';
import TermsOfService from './TermsConditions';
import PrivacyPolicy from './PrivacyPolicy';

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg max-w-4xl max-h-[90vh] overflow-auto">
        <button onClick={onClose} className="float-right text-gray-600 hover:text-gray-800">
          &times;
        </button>
        {children}
      </div>
    </div>
  );
}

function Home() {
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
        <LPHero />
      </main>
      
      <footer className="bg-gray-100 py-4">
        <div className="container mx-auto px-4 text-center text-sm text-gray-600">
          <button onClick={() => setIsTermsOpen(true)} className="hover:underline mr-4">
            Terms of Service
          </button>
          <button onClick={() => setIsPrivacyOpen(true)} className="hover:underline">
            Privacy Policy
          </button>
        </div>
      </footer>

      <Modal isOpen={isTermsOpen} onClose={() => setIsTermsOpen(false)}>
        <TermsOfService />
      </Modal>

      <Modal isOpen={isPrivacyOpen} onClose={() => setIsPrivacyOpen(false)}>
        <PrivacyPolicy />
      </Modal>
    </div>
  );
}

export default Home;