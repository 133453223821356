import React from 'react';

const TermsOfService = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Terms of Service for The Crown List</h1>
      <p className="text-sm text-gray-600 mb-6">Last Updated: August 21, 2024</p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">1. Acceptance of Terms</h2>
        <p className="text-gray-600">
          By accessing or using The Crown List website and services, you agree to be bound by these Terms of Service ("Terms"). If you disagree with any part of the terms, you may not access the service.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">2. Description of Service</h2>
        <p className="text-gray-600">
          The Crown List is an online community and business directory for pageants. We provide a platform for users to connect, share information, and access resources related to pageants.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">3. User Registration</h2>
        <p className="text-gray-600">
          To use certain features of the service, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">4. User Conduct</h2>
        <p className="text-gray-600">You agree not to use the service to:</p>
        <ul className="list-disc list-inside text-gray-600 ml-4">
          <li>Post or transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or invasive of another's privacy.</li>
          <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.</li>
          <li>Engage in any activity that interferes with or disrupts the service or servers and networks connected to the service.</li>
          <li>Violate any applicable local, state, national, or international law.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">5. Content</h2>
        <p className="text-gray-600">
          You retain all rights in, and are solely responsible for, the content you post to The Crown List. By posting content, you grant The Crown List a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, modify, publicly perform, publicly display, reproduce, and distribute such content on and through the service.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">6. Intellectual Property</h2>
        <p className="text-gray-600">
          The service and its original content, features, and functionality are and will remain the exclusive property of The Crown List and its licensors. The service is protected by copyright, trademark, and other laws of both the United States and foreign countries.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">7. Termination</h2>
        <p className="text-gray-600">
          We may terminate or suspend your account and bar access to the service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">8. Limitation of Liability</h2>
        <p className="text-gray-600">
          In no event shall The Crown List, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the service.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">9. Changes to Terms</h2>
        <p className="text-gray-600">
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide notice of any significant changes by posting the new Terms on this page. Your continued use of the service after any such changes constitutes your acceptance of the new Terms.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">10. Governing Law</h2>
        <p className="text-gray-600">
          These Terms shall be governed and construed in accordance with the laws of Georgia, USA, without regard to its conflict of law provisions.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">11. Contact Us</h2>
        <p className="text-gray-600">
          If you have any questions about these Terms, please contact us at{' '}
          <a href="mailto:talisha@thecrownlist.com" className="text-blue-600 hover:underline">
            talisha@thecrownlist.com
          </a>.
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;