import React, { useState } from 'react';
import { Calendar, Clock, DollarSign, Plus, Trash2, Edit2, X, ArrowLeft, MessageSquare } from 'lucide-react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import VendorDashboardLayout from '../components/VendorDashboardLayout';

// Set up the localizer for react-big-calendar
const localizer = momentLocalizer(moment);

// Custom styles for the calendar
const calendarStyles = {
  '.rbc-event': {
    backgroundColor: '#8B5CF6', // Purple-600
    borderColor: '#7C3AED', // Purple-700
  },
  '.rbc-selected': {
    backgroundColor: '#6D28D9 !important', // Purple-800
  },
  '.rbc-today': {
    backgroundColor: '#F3E8FF', // Purple-100
  },
  '.rbc-current-time-indicator': {
    backgroundColor: '#7C3AED', // Purple-700
  },
};

const BookingsPage = () => {
  const [bookings, setBookings] = useState([
    { id: 1, clientName: 'Alice Johnson', service: 'Hair Styling', date: '2024-08-20', time: '10:00 AM', paid: 80, owed: 20 },
    { id: 2, clientName: 'Bob Smith', service: 'Makeup', date: '2024-08-21', time: '2:00 PM', paid: 150, owed: 0 },
  ]);

  const [sessions, setSessions] = useState([
    { id: 1, service: 'Hair Styling', description: 'Professional hair styling service', price: 100, datesAvailable: '2024-08-20,2024-08-21,2024-08-22', events: ['Miss USA Pageant'] },
    { id: 2, service: 'Makeup', description: 'Full face makeup application', price: 150, datesAvailable: '2024-08-21,2024-08-22,2024-08-23', events: [] },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSession, setCurrentSession] = useState(null);
  const [newSession, setNewSession] = useState({ service: '', duration: '', price: '' });
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [viewMode, setViewMode] = useState('list'); // 'list' or 'calendar'
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleAddSession = () => {
    setCurrentSession(null);
    setIsModalOpen(true);
  };

  const handleEditSession = (session) => {
    setCurrentSession(session);
    setIsModalOpen(true);
  };

  const handleDeleteSession = (id) => {
    setSessions(sessions.filter(session => session.id !== id));
  };

  const handleSaveSession = (sessionData) => {
    if (currentSession) {
      setSessions(sessions.map(session => 
        session.id === currentSession.id ? { ...session, ...sessionData } : session
      ));
    } else {
      setSessions([...sessions, { ...sessionData, id: sessions.length + 1 }]);
    }
    setIsModalOpen(false);
  };

  const handleBookingClick = (booking) => {
    setSelectedBooking(booking);
  };

  const closeBookingDetails = () => {
    setSelectedBooking(null);
  };

  const handleDeleteBooking = (id) => {
    setBookings(bookings.filter(booking => booking.id !== id));
    setSelectedBooking(null);
    setShowConfirmDelete(false);
  };

  const handleEventSelect = (event) => {
    setSelectedBooking(event.resource);
    setViewMode('list');
  };

  // Convert bookings to events for the calendar
  const calendarEvents = bookings.map(booking => ({
    title: `${booking.clientName} - ${booking.service}`,
    start: new Date(`${booking.date} ${booking.time}`),
    end: moment(`${booking.date} ${booking.time}`).add(1, 'hour').toDate(), // Assuming 1-hour duration
    resource: booking,
  }));

  return (
    <div>
        <Navbar />
        <VendorDashboardLayout >
    <div className="container mx-auto p-6">

      {/* View Toggle */}
      <div className="mb-4">
        <button
          onClick={() => setViewMode('list')}
          className={`mr-2 px-4 py-2 rounded ${viewMode === 'list' ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}
        >
          List View
        </button>
        <button
          onClick={() => setViewMode('calendar')}
          className={`px-4 py-2 rounded ${viewMode === 'calendar' ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}
        >
          Calendar View
        </button>
      </div>

      {/* Existing Bookings or Booking Details */}
      {viewMode === 'list' && (
        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            {selectedBooking ? 'Booking Details' : 'Current Bookings'}
          </h2>
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            {!selectedBooking ? (
              <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <table className="w-full">
                  <thead className="bg-purple-100">
                    <tr>
                      <th className="px-4 py-2 text-left">Client Name</th>
                      <th className="px-4 py-2 text-left">Service</th>
                      <th className="px-4 py-2 text-left">Date</th>
                      <th className="px-4 py-2 text-left">Time</th>
                      <th className="px-4 py-2 text-left">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.map((booking) => (
                      <tr key={booking.id} className="border-t">
                        <td className="px-4 py-2">{booking.clientName}</td>
                        <td className="px-4 py-2">{booking.service}</td>
                        <td className="px-4 py-2">{booking.date}</td>
                        <td className="px-4 py-2">{booking.time}</td>
                        <td className="px-4 py-2">
                          <button
                            onClick={() => handleBookingClick(booking)}
                            className="text-purple-600 hover:text-purple-800"
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="p-6">
                <button
                  onClick={() => setSelectedBooking(null)}
                  className="mb-4 flex items-center text-purple-600 hover:text-purple-800"
                >
                  <ArrowLeft size={20} className="mr-2" /> Back to Bookings
                </button>
                <div className="space-y-4">
                  <p><strong>Client:</strong> {selectedBooking.clientName}</p>
                  <p><strong>Service:</strong> {selectedBooking.service}</p>
                  <p><strong>Date:</strong> {selectedBooking.date}</p>
                  <p><strong>Time:</strong> {selectedBooking.time}</p>
                  <p><strong>Amount Paid:</strong> ${selectedBooking.paid}</p>
                  <p><strong>Amount Owed:</strong> ${selectedBooking.owed}</p>
                </div>
                <div className="mt-6 flex space-x-4">
                  <button
                    onClick={() => setShowConfirmDelete(true)}
                    className="flex-1 bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 flex items-center justify-center"
                  >
                    <Trash2 size={20} className="mr-2" /> Delete Booking
                  </button>
                  <button
                    className="flex-1 bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 flex items-center justify-center"
                  >
                    <MessageSquare size={20} className="mr-2" /> Message Client
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Calendar View */}
      {viewMode === 'calendar' && (
        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Booking Calendar</h2>
          <div className="bg-white shadow-md rounded-lg p-4" style={{ height: '500px' }}>
            <BigCalendar
              localizer={localizer}
              events={calendarEvents}
              startAccessor="start"
              endAccessor="end"
              onSelectEvent={handleEventSelect}
              style={calendarStyles}
            />
          </div>
        </div>
      )}

      {/* Confirmation Modal for Delete */}
      {showConfirmDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <h3 className="text-xl font-semibold mb-4">Confirm Deletion</h3>
            <p>Are you sure you want to delete this booking?</p>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmDelete(false)}
                className="px-4 py-2 border rounded text-gray-600 hover:bg-gray-100"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDeleteBooking(selectedBooking.id)}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Manage Sessions */}
      <div>
        <h2 className="text-2xl font-semibold mb-4">Manage Sessions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {sessions.map((session) => (
            <div key={session.id} className="bg-white shadow-md rounded-lg p-4">
              <h3 className="text-lg font-semibold mb-2">{session.service}</h3>
              <p className="text-sm text-gray-600 mb-2">{session.description}</p>
              <p className="flex items-center mb-2"><DollarSign size={16} className="mr-1" />{session.price}</p>
              <p className="text-sm text-gray-600 mb-2">Available: {session.datesAvailable}</p>
              {session.events.length > 0 && (
                <p className="text-sm text-purple-600 mb-2">Events: {session.events.join(', ')}</p>
              )}
              <div className="flex justify-end space-x-2 mt-4">
                <button onClick={() => handleEditSession(session)} className="text-blue-600 hover:text-blue-800">
                  <Edit2 size={20} />
                </button>
                <button onClick={() => handleDeleteSession(session.id)} className="text-red-600 hover:text-red-800">
                  <Trash2 size={20} />
                </button>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={handleAddSession}
          className="mt-4 bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 flex items-center"
        >
          <Plus size={20} className="mr-2" /> Add Session
        </button>
      </div>

      {/* Session Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <h3 className="text-xl font-semibold mb-4">{currentSession ? 'Edit Session' : 'Add New Session'}</h3>
            <SessionForm
              initialData={currentSession}
              onSave={handleSaveSession}
              onCancel={() => setIsModalOpen(false)}
            />
          </div>
        </div>
      )}
    </div>
    </VendorDashboardLayout >
    <Footer />
    </div>
  );
};

const SessionForm = ({ initialData, onSave, onCancel }) => {
  const [formData, setFormData] = useState(initialData || {
    service: '',
    description: '',
    price: '',
    datesAvailable: '',
    events: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="service"
        value={formData.service}
        onChange={handleChange}
        placeholder="Service"
        className="w-full p-2 mb-4 border rounded"
        required
      />
      <textarea
        name="description"
        value={formData.description}
        onChange={handleChange}
        placeholder="Description"
        className="w-full p-2 mb-4 border rounded"
        required
      />
      <input
        type="number"
        name="price"
        value={formData.price}
        onChange={handleChange}
        placeholder="Price"
        className="w-full p-2 mb-4 border rounded"
        required
      />
      <input
        type="text"
        name="datesAvailable"
        value={formData.datesAvailable}
        onChange={handleChange}
        placeholder="Dates Available (comma-separated)"
        className="w-full p-2 mb-4 border rounded"
        required
      />
      <input
        type="text"
        name="events"
        value={formData.events.join(',')}
        onChange={(e) => setFormData({ ...formData, events: e.target.value.split(',') })}
        placeholder="Events (comma-separated)"
        className="w-full p-2 mb-4 border rounded"
      />
      <div className="flex justify-end space-x-2">
        <button type="button" onClick={onCancel} className="px-4 py-2 border rounded">
          Cancel
        </button>
        <button type="submit" className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700">
          Save
        </button>
      </div>
    </form>
  );
};

export default BookingsPage;