import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Privacy Policy for The Crown List</h1>
      <p className="text-sm text-gray-600 mb-6">Last updated: August 21, 2024</p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">1. Introduction</h2>
        <p className="text-gray-600">
          Welcome to The Crown List ("we," "our," or "us"). We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our online community and business directory for pageants.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">2. Information We Collect</h2>
        <p className="text-gray-600">We collect the following personal information from our users:</p>
        <ul className="list-disc list-inside text-gray-600 ml-4">
          <li>Name</li>
          <li>Email address</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">3. How We Use Your Information</h2>
        <p className="text-gray-600">We use the information we collect for the following purposes:</p>
        <ul className="list-disc list-inside text-gray-600 ml-4">
          <li>To provide and maintain our service</li>
          <li>To send you email marketing communications</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">4. Information Sharing and Disclosure</h2>
        <p className="text-gray-600">
          We do not share or sell your personal information to third parties.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">5. Data Security</h2>
        <p className="text-gray-600">
          We implement security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">6. Cookies and Tracking Technologies</h2>
        <p className="text-gray-600">
          We do not use cookies or similar tracking technologies.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">7. Your Data Protection Rights</h2>
        <p className="text-gray-600">
          You can access, update, or delete your information at any time through your settings page on our website.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">8. Changes to This Privacy Policy</h2>
        <p className="text-gray-600">
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-3 text-gray-700">9. Contact Us</h2>
        <p className="text-gray-600">
          If you have any questions about this Privacy Policy, please contact us at{' '}
          <a href="mailto:talisha@thecrownlist.com" className="text-blue-600 hover:underline">
            talisha@thecrownlist.com
          </a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;