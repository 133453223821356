import React, { useState, useEffect } from 'react';
import { Crown, Menu, X, User, ChevronDown, LogOut, Settings, ChevronRight } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import LoginModal from './LoginModal';
import OnboardingModal from './OnboardingModal';
import { useAuthenticator } from '@aws-amplify/ui-react';
import MessengerButton from './MessengerButton';
import NotificationDropdown from './NotificationIcon';
import { generateClient } from "aws-amplify/api";
import { createUser } from '../graphql/mutations';
import { getUser } from '../graphql/queries';
import { StorageImage } from '@aws-amplify/ui-react-storage';


const client = generateClient();

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [isDirectoryHovered, setIsDirectoryHovered] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);

  const navItems = [
    { 
      name: 'Directory', 
      href: '/directory',
      categories: [
        'Pageants', 'Hair & Makeup', 'Coaching', 'Retailers', 'Jewelry & Accessories',
        'Photography', 'Shoes', 'Gifts', 'Designers', 'Graphic Design', 
        { 
          name: 'Beauty Services',
          subCategories: ['Spray Tans', 'Teeth Whitening']
        }
      ]
    },
    { name: 'Events', href: '/events' },
    { name: 'Resources', href: '/resources' },
    { name: 'Forum', href: '/forum' },
    { name: 'Blog', href: '/blog' },
  ];

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (user) {
      console.log('Auth session:', user);
      checkUserInDatabase();
    }
  }, [user]);

  const checkUserInDatabase = async () => {
    try {
      if (!user || !user.username) {
        console.log('User data not fully loaded yet');
        return;
      }

      console.log('Querying database for user:', user.username);
      const userData = await client.graphql({
        query: getUser,
        variables: { id: user.username }
      });
      
      console.log('User data from database:', userData.data.getUser);
      
      if (!userData.data.getUser) {
        console.log('User not found in database, triggering onboarding');
        setShowOnboarding(true);
      } else {
        console.log('User found in database, no onboarding needed');
        setUserProfile(userData.data.getUser);
      }
    } catch (error) {
      console.error('Error checking user in database:', error);
      console.log('Triggering onboarding due to error');
      setShowOnboarding(true);
    }
  };

  const handleOnboardingComplete = async (userData) => {
    console.log('Onboarding data received:', userData);
    try {
      const newUserData = {
        id: user.username, // Use 'id' instead of 'userId'
        email: user.signInDetails.loginId,
        name: userData.fullName,
        categories: userData.categories,
        verified: false,
        profileUrl: generateProfileUrl(userData.fullName),
        profileImage: userData.profileImage,
        isPublic: true, // Add this field with a default value
        followers: 0, // Add this field with a default value
        likes: 0, // Add this field with a default value
      };
      console.log('Sending new user data to create:', newUserData);
      const newUser = await client.graphql({
        query: createUser,
        variables: { input: newUserData }
      });
      console.log('New user created:', newUser);
      setShowOnboarding(false);
    } catch (error) {
      console.error('Error creating new user:', error);
    }
  };

  const generateProfileUrl = (fullName) => {
    const nameParts = fullName.toLowerCase().replace(/[^a-z0-9]/g, '-').split('-');
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
    const randomNum = Math.floor(Math.random() * 1000);
    return `/${firstName}-${lastName}${randomNum}`;
  };

  return (
    <nav className="bg-white shadow-md sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <a href="/" className="flex items-center">
              <Crown className="h-8 w-8 text-purple-600" />
              <span className="ml-2 text-xl font-bold text-gray-800">The Crown List</span>
            </a>
          </div>
          
          <div className="hidden md:flex items-center justify-center flex-1">
            {navItems.map((item) => (
              <div
                key={item.name}
                className="relative"
                onMouseEnter={() => item.name === 'Directory' && setIsDirectoryHovered(true)}
                onMouseLeave={() => {
                  setIsDirectoryHovered(false);
                  setHoveredCategory(null);
                }}
              >
                <a
                  href={item.href}
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    location.pathname === item.href
                      ? 'text-purple-600'
                      : 'text-gray-600 hover:text-purple-600'
                  }`}
                >
                  {item.name}
                  {item.name === 'Directory' && <ChevronDown className="inline-block w-4 h-4 ml-1" />}
                </a>
                {item.name === 'Directory' && isDirectoryHovered && (
                  <div className="absolute left-0 mt-2 w-56 bg-white border border-gray-200 rounded-md shadow-lg py-1 z-20">
                    {item.categories.map((category) => (
                      <div
                        key={typeof category === 'string' ? category : category.name}
                        className="relative"
                        onMouseEnter={() => setHoveredCategory(category.name || category)}
                        onMouseLeave={() => setHoveredCategory(null)}
                      >
                        <a
                          href={`/directory/${(category.name || category).toLowerCase().replace(/\s+/g, '-')}`}
                          className="flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-purple-600 hover:font-bold"
                        >
                          {category.name || category}
                          {category.subCategories && <ChevronRight className="w-4 h-4" />}
                        </a>
                        {category.subCategories && hoveredCategory === category.name && (
                          <div className="absolute left-full top-0 w-48 bg-white border border-gray-200 rounded-md shadow-lg py-1 z-30">
                            {category.subCategories.map((subCategory) => (
                              <a
                                key={subCategory}
                                href={`/directory/${subCategory.toLowerCase().replace(/\s+/g, '-')}`}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-purple-600 hover:font-bold"
                              >
                                {subCategory}
                              </a>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          
          <div className="hidden md:flex items-center space-x-4">
            <a href="/submit-listing" className="bg-purple-600 text-white px-4 py-2 rounded-md text-sm font-medium">
              Submit Listing
            </a>
            {user && (
              <>
                <MessengerButton />
                <NotificationDropdown />
              </>
            )}
            {user ? (
              <div className="relative">
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center text-gray-800 rounded-md text-sm font-medium"
                >
                  {userProfile && userProfile.profileImage ? (
                   <div className='h-10 w-10 object-cover rounded-full'>
                     <StorageImage 
                      alt={userProfile.name} 
                      path={`public/${userProfile.profileImage}`}
                      className="w-8 h-8  rounded-full object-cover"
                    />
                    </div>
                  ) : (
                    <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center">
                      <User className="w-5 h-5 text-gray-600" />
                    </div>
                  )}
                  <ChevronDown className="w-4 h-4 ml-1" />
                </button>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg py-1 z-20">
                    <a href={userProfile && userProfile.profileUrl ? `/profile${userProfile.profileUrl}` : '#'} className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100">
                      <User className="w-4 h-4 mr-2" />
                      My Profile
                    </a>
                    <a href="/profile/settings" className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100">
                      <Settings className="w-4 h-4 mr-2" />
                      Settings
                    </a>
                    <button
                      onClick={signOut}
                      className="flex items-center w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      <LogOut className="w-4 h-4 mr-2" />
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <button onClick={toggleModal} className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md text-sm font-medium">
                Login
              </button>
            )}
          </div>
          
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navItems.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  location.pathname === item.href
                    ? 'text-purple-600'
                    : 'text-gray-600 hover:text-purple-600'
                }`}
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="pt-4 pb-3 border-t border-gray-200">
            <div className="flex items-center px-5">
              <a href="/submit-listing" className="bg-purple-600 text-white px-4 py-2 rounded-md text-sm font-medium mr-2 w-full">
                Submit Listing
              </a>
            </div>
            {user && (
              <div className="mt-3 px-2 space-y-2">
                <MessengerButton className="w-full" />
                <NotificationDropdown isMobile={true} />
              </div>
            )}
            <div className="mt-3 px-2 space-y-1">
              {user ? (
                <button
                  onClick={signOut}
                  className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md text-sm font-medium w-full"
                >
                  Logout
                </button>
              ) : (
                <button onClick={toggleModal} className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md text-sm font-medium w-full">
                  Login
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      
      {isModalOpen && <LoginModal onClose={toggleModal} />}
      
      {showOnboarding && (
        <OnboardingModal
          onComplete={handleOnboardingComplete}
          userEmail={user?.signInDetails?.loginId}
        />
      )}
    </nav>
  );
};

export default Navbar;