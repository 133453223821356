import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { generateClient } from "aws-amplify/api";
import { searchBusinesses } from "../graphql/queries";
import SideFilter from '../components/SideFilter';
import HorizontalBusinessCard from '../components/HorizontalBusinessCard';
import Advertisement from '../components/Advertisement';
import Pagination from '../components/Pagination';
import SearchBox from '../components/SearchBox';
import { Facebook, Instagram, Twitter } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import HorizontalBusinessCardSkeleton from '../components/HorizontalBusinessCardSkeleton';

const client = generateClient();

const SearchResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [advertisements, setAdvertisements] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('q') || '';
    setSearchQuery(query);
    fetchSearchResults(query);
    fetchAdvertisements();
  }, [location.search]);

  const fetchSearchResults = async (query) => {
    setLoading(true);
    setError(null);
    try {
      console.log("Fetching search results for query:", query);
      const response = await client.graphql({
        query: searchBusinesses,
        variables: {
          filter: {
            or: [
              { name: { matchPhrasePrefix: query } },
              { category: { matchPhrasePrefix: query } },
              { tags: { matchPhrasePrefix: query } }
            ]
          },
          limit: 20 // Adjust as needed
        }
      });
      console.log("Raw API response:", response);
      
      if (response.data && response.data.searchBusinesses && Array.isArray(response.data.searchBusinesses.items)) {
        const items = response.data.searchBusinesses.items;
        console.log("Parsed search results:", items);
        setSearchResults(items);
      } else {
        console.error("Unexpected API response structure:", response);
        setError("An error occurred while processing search results. Please try again.");
        setSearchResults([]);
      }
    } catch (err) {
      console.error("Error fetching search results:", err);
      setError("An error occurred while fetching search results. Please try again.");
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchAdvertisements = () => {
    // Replace this with your actual API call for fetching advertisements
    setAdvertisements([
      {
        id: 1,
        title: "Premium Pageant Dresses",
        description: "Exclusive designs for all age groups. Get 10% off your first order!",
        imageUrl: "https://example.com/ad-image1.jpg",
        linkUrl: "https://example.com/premium-dresses",
        linkText: "Shop Now",
        badge: "Top Rated"
      },
      // More advertisement objects...
    ]);
  };

  const handleFilterChange = (filters) => {
    // Handle filter changes here
    console.log('Filters changed:', filters);
    // You might want to update the search query based on filters
    // and call fetchSearchResults again
  };

  const handleSearch = (query) => {
    navigate(`/search?q=${encodeURIComponent(query)}`);
  };

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchResults.slice(indexOfFirstItem, indexOfLastItem);

  const renderSearchResults = () => {
    if (loading) {
      return Array(itemsPerPage).fill().map((_, index) => (
        <HorizontalBusinessCardSkeleton className="pb-24" key={`skeleton-${index}`} />
      ));
    }

    if (error) {
      return <div className="text-red-500">{error}</div>;
    }

    if (!Array.isArray(searchResults) || searchResults.length === 0) {
      return <div>No results found for "{searchQuery}"</div>;
    }

    console.log("Rendering search results:", searchResults);

    const results = [];
    currentItems.forEach((business, index) => {
      if (index === 0 && advertisements.length > 0) {
        results.push(<Advertisement key={`ad-top`} ad={advertisements[0]} />);
      }
      if (business && typeof business === 'object') {
        const { 
          id, 
          name, 
          logo, 
          images, 
          isVerified, 
          isFeatured, 
          rating, 
          reviewCount, 
          location, 
          price, 
          ages, 
          category, 
          socialLinks, 
          responseTime 
        } = business;
        
        results.push(
          <HorizontalBusinessCard 
            key={id || index}
            name={name || '--'}
            logo={logo || ''}
            images={images || []}
            isVerified={isVerified || false}
            isFeatured={isFeatured || false}
            rating={rating || 0}
            reviewCount={reviewCount || 0}
            location={location ? `${location.city}, ${location.state}` : '--'}
            price={price || '--'}
            ages={ages || '--'}
            category={category || '--'}
            socialLinks={socialLinks || []}
            responseTime={responseTime || '--'}
          />
        );
      } else {
        console.error("Invalid business object at index", index, business);
      }
      if (index === 2 && advertisements.length > 1) {
        results.push(<Advertisement key={`ad-middle`} ad={advertisements[1]} />);
      }
    });
    return results;
  };

  return (
    <div>
      <Navbar />
      <header className="mb-8 w-full h-36 bg-purple-100 flex flex-col justify-center items-center">
        <h1 className="text-2xl font-bold mb-4">Search Results</h1>
        <div className="w-full max-w-2xl">
          <SearchBox 
            initialQuery={searchQuery} 
            onSearch={handleSearch} 
          />
        </div>
      </header>

      <div className="container mx-auto px-4 py-8">
        <div className="flex gap-8">
          <div className="w-1/4">
            <div className="sticky top-4">
              <SideFilter 
                onFilterChange={handleFilterChange} 
                adImageVertical="https://relume-assets.s3.amazonaws.com/placeholder-image.svg"
                adImageSquare="https://relume-assets.s3.amazonaws.com/placeholder-image.svg"
              />
            </div>
          </div>
          <div className="w-3/4 flex flex-col justify-between">
            <div>
              {renderSearchResults()}
            </div>
            <div className="mt-8">
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(searchResults.length / itemsPerPage)}
                onPageChange={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SearchResultsPage;