import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Directory from './pages/Directory';
import DirectoryCategory from './pages/DirectoryCategory';
import DirectoryListing from './pages/DirectoryListing';
import Events from './pages/Events';
import Resources from './pages/Resources';
import Forum from './pages/Forum';
import ForumCategory from './pages/ForumCategory';
import ForumPost from './pages/ForumPost';
import Blog from './pages/Blog';
import AboutUs from './pages/AboutUs';
import SubmitListing from './pages/SubmitListing';
import Profile from './pages/Profile';
import VendorDashboard from './pages/VendorDashboard';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import SearchResultsPage from './pages/SearchResultsPage'; 
import MediaKit from './pages/MediaKit';
import HelpPage from './pages/HelpPage';
import FAQPage from './pages/FAQPage';
import DirectorsInfoPage from './components/DirectorsInfoPage';
import BusinessInfoPage from './pages/BusinessInfoPage';
import DashboardWallet from './pages/DashboardWallet';
import BlogPostPage from './pages/BlogPostPage';
import EventDetailPage from './pages/EventDetailPage';
import BusinessMessages from './pages/BusinessMessages';
import BookingsPage from './pages/BookingsPage';
import ListingManager from './pages/ListingManager';
import VendorReviewsPage from './pages/VendorReviewsPage';
import BookmarksPage from './pages/BookmarksPage';
import EventsDashboardPage from './pages/EventsDashboardPage';
import InvoicesPage from './pages/InvoicesPage';
import AccountSettingsPage from './pages/AccountSettingsPage';
import { Authenticator } from '@aws-amplify/ui-react';
import Messages from './pages/Messages';
import AdminDashboard from './pages/AdminDashboard';


function App() {
  return (
    <Authenticator.Provider>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/directory" element={<Directory />} />
        <Route path="/directory/:category" element={<DirectoryCategory />} />
        <Route path="/directory/listing/:id" element={<DirectoryListing />} />
        <Route path="/events" element={<Events />} />
        <Route path="/events/:id" element={<EventDetailPage />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/forum/:category" element={<ForumCategory />} />
        <Route path="/forum/:category/:postId" element={<ForumPost />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogPostPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/submit-listing" element={<SubmitListing />} />
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/dashboard" element={<VendorDashboard />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/search" element={<SearchResultsPage />} /> 
        <Route path="/media-kit" element={<MediaKit />} />
        <Route path="/help" element={<HelpPage />} />
        <Route path="/faqs" element={<FAQPage />} />
        <Route path="/for-directors" element={<DirectorsInfoPage />} />
        <Route path='/for-businesses' element={<BusinessInfoPage />} />
        <Route path='/dashboard/wallet' element={<DashboardWallet />} />
        <Route path='/dashboard/messages' element={<BusinessMessages /> } />
        <Route path="/dashboard/bookings" element={<BookingsPage />} />
        <Route path="/dashboard/listings" element={<ListingManager />} />
        <Route path="/dashboard/reviews" element={<VendorReviewsPage />} />
        <Route path="/dashboard/bookmarks" element={<BookmarksPage />} />
        <Route path="/dashboard/events" element={<EventsDashboardPage />} />
        <Route path="/dashboard/invoices" element={<InvoicesPage />} />
        <Route path="/profile/settings" element={<AccountSettingsPage />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/messages/:id" element={<Messages />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Router>
    </Authenticator.Provider>
  );
}

export default App;